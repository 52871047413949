#main-grid {
  width: 100%;
  height: 100%;
  padding: 25px;

  display: grid;
  position: relative;
}

#main-content .tile-username > .ellipsis-tile-username {
  max-width: 150px;
}

#main-grid.meeting-main-with-sidebar .tile-username > .ellipsis-tile-username {
  max-width: 60px;
}

#main-grid.full-screen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
}

/* Main Default */
#main-grid > main {
  grid-area: main;
  overflow-y: auto;
  overflow-x: hidden;
  /* background-color: red; */

  position: relative;
}
#main-grid > main > .main-content-inner {
  width: 100%;
  height: 100%;
}

#main-grid > main > .main-content-inner.screensharing {
  height: calc(100% - 50px);
}

/* Footer */
#main-grid > #meeting-footer {
  grid-area: footer;
  margin-top: 10px;
  position: relative;
}

#meeting-footer > .left-footer {
  position: absolute;
  left: 10px;
  top: 10px;
  display: flex;
}

#meeting-footer > .left-footer button.controlbutton {
  box-shadow: none;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  z-index: 3;
  margin-right: 10px;
}

#meeting-footer > .left-footer button.controlbutton:disabled {
  cursor: default;
  opacity: 0.3;
}

/* Sidebar */
#main-grid > aside {
  grid-area: aside;
  margin-right: 10px;

  /* background-color: blue; */
}

/* Participant */
.participants-wrapper .participant {
  width: 120px;
  height: 120px;
  position: relative;
  margin: 0;

  border-radius: 10px;
  overflow: hidden;
}

/* Non Meeting Mode */
#main-grid.non-meeting {
  grid-template-columns: 1fr 0;
  grid-template-rows: 1fr;
  grid-template-areas: 'main aside';
}

/* Meeting Full Main Mode */
#main-grid.meeting-full-main {
  grid-template-columns: 1fr 0;
  grid-template-rows: 1fr 70px;
  grid-template-areas:
    'main aside'
    'footer footer';
}

/* Meeting Full Sidebar Mode */
#main-grid.meeting-full-sidebar {
  grid-template-columns: 0 1fr;
  grid-template-rows: 1fr 50px 70px;
  grid-template-areas:
    'main aside'
    'header header'
    'footer footer';
}

#main-grid.meeting-full-sidebar > aside {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#main-grid.meeting-full-sidebar .participants-wrapper {
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
  justify-items: center;
  height: 100%;
  width: 100%;
}

#main-grid.meeting-full-sidebar .participants-wrapper .participant {
  width: 100%;
  height: 100%;
}

#main-grid.meeting-full-sidebar .participants-wrapper {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: minmax(150px, 1fr);
}

#main-grid.meeting-main-with-sidebar
  > aside#participants-sidebar
  .participants-wrapper
  .participant
  .avatar-img-wrapper {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
}

.participant {
  position: relative;
}

.participant .avatar-img-wrapper {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100px;
  min-height: 100px;
  width: 100px;
  height: 100px;
}

.participant .avatar-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.participant > video + .avatar-img-wrapper {
  z-index: -1;
}

@media screen and (min-width: 720px) {
  /* #main-grid.meeting-full-sidebar .participants-wrapper .participant {
    width: 195px;
    height: 160px;
  }

  #main-grid.meeting-full-sidebar .participants-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  */
  #main-grid.meeting-full-sidebar .participants-wrapper.p-1 {
    grid-template-columns: repeat(1, 1fr);
  }

  /* 2 */
  #main-grid.meeting-full-sidebar .participants-wrapper.p-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  /* 3 */
  #main-grid.meeting-full-sidebar .participants-wrapper.p-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  /* 4 */
  #main-grid.meeting-full-sidebar .participants-wrapper.p-4 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  /* #main-grid.meeting-full-sidebar .participants-wrapper.p-1 .participant {
    width: 390px;
    height: 320px;
  }

  #main-grid.meeting-full-sidebar .participants-wrapper.p-2 .participant,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-3 .participant,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-4 .participant {
    width: 292.5px;
    height: 240px;
  } */

  /* 5 */
  /* #main-grid.meeting-full-sidebar .participants-wrapper.p-5,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-6,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-7,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-8,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-9,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-10,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-11,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-12,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-13,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-14,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-15,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-16 {
    grid-template-columns: repeat(3, 1fr);
  } */
}

@media screen and (min-width: 1180px) {
  /* 5 */
  /* #main-grid.meeting-full-sidebar .participants-wrapper.p-5,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-6,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-7,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-8,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-9,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-10,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-11,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-12,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-13,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-14,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-15,
  #main-grid.meeting-full-sidebar .participants-wrapper.p-16 {
    grid-template-columns: repeat(5, 1fr);
  } */
}

/* Meeting Sidebar Mode */
#main-grid.meeting-main-with-sidebar {
  grid-template-columns: 1fr 150px;
  grid-template-rows: 1fr 70px;
  grid-template-areas:
    'main aside'
    'footer aside';
  padding-right: 0;
}

#main-grid.meeting-main-with-sidebar .participants-wrapper {
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
  justify-items: center;
}

/* Scroll Bar */
#participants-sidebar {
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#participants-sidebar::-webkit-scrollbar {
  display: none;
}

.participant {
  width: 120px;
  height: 120px;
  position: relative;
  margin: 0;

  border-radius: 10px;
  overflow: hidden;
}

#main-grid main .participant.main-participant {
  width: 100%;
  height: 100%;
}

#main-grid main .participant.main-participant .presentation-label {
  position: absolute;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: 400;
  top: 10px;
  left: 10px;
}

#main-grid #main-content .local-presenter-label,
#main-grid.meeting-full-sidebar header.local-presenter-label {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 10px;
  height: 40px;
  max-height: 40px;
  margin-top: 10px;
  position: relative;
}

#main-grid.meeting-full-sidebar header.local-presenter-label {
  grid-area: header;
  padding: 0;
  margin-bottom: 10px;
}

#main-grid #main-content .local-presenter-label-bg,
#main-grid.meeting-full-sidebar
  header.local-presenter-label
  .local-presenter-label-bg {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#main-grid
  main
  .participant.main-participant
  .presentation-label
  .current-presenter,
#participants-sidebar
  .participant.main-participant
  .presentation-label
  .current-presenter {
  font-weight: 500;
}

#participants-sidebar .participant.main-participant .presentation-label {
  position: absolute;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: 400;
  top: 10px;
  left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  font-size: 12px;
}

#main-grid .meeting-controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#main-grid .meeting-controls button {
  margin-left: 3px;
  margin-right: 3px;
}

#main-grid .meeting-controls button.controlbutton {
  box-shadow: none;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  z-index: 3;
}

#main-grid .meeting-controls button.controlbutton:disabled {
  cursor: default;
  opacity: 0.3;
}

.room-modal-backdrop {
  position: fixed;
  /* background-color: red; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000000;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(34, 34, 34, 0.5);
}

.room-modal-backdrop .room-modal {
  /* background-color: white;
  color: #363B45; */
  width: 400px;
  /* height: 90vh; */

  border-radius: 10px;
  font-weight: 400;
  position: relative;
  /* -webkit-animation: blgkoZ 0.2s ease-out;
  animation: blgkoZ 0.2s ease-out; */
  max-width: 400px;
  max-height: 90vh;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
}

.room-modal-backdrop .room-modal .header {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}

.room-modal .field-wrapper .label {
  text-align: left;
  margin-bottom: 10px;
  margin-top: 20px;
  font-weight: 500;
}

.room-modal .field-wrapper .select-custom {
  padding: 8px 24px 8px 16px;
  border: 1px solid;
  border-radius: 32px;
  cursor: pointer;
  width: 100%;
}

.room-modal .default-options {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  justify-content: center;
}

.room-modal .layout-options {
  display: flex;
  padding: 20px 10px;
  align-items: center;
}

.room-modal .layout-options > div:first-child {
  flex: 1;
  text-align: left;
}

.room-modal .default-options .thumb-container {
  font-size: 24px;
}

.room-modal .default-options .thumb-container img {
  height: 30px;
}

.room-modal .default-options .thumb-container {
  width: 80px;
  border-radius: 10px;
  margin-top: 20px;
}

.room-modal .default-options .thumb-container .bg-option-label {
  font-size: 13px;
  margin-top: 10px;
}

.modal-video-track-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  margin-top: 20px;
  min-width: 320px;
  min-height: 230px;
  max-width: 320px;
  max-height: 230px;
  width: 320px;
  height: 230px;
  border-radius: 10px;
  background-color: black;
}

.modal-video-track-container video {
  /* width: 273px !important;
  height: 224px !important; */
  position: relative;
  margin: 0;
  border-radius: 10px;
}

.thumb-container h1 {
  border: 2px solid black;
  /* height: 90px; */
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.thumb-container img {
  height: 90px;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
}

.thumb-container > img {
  border: 2px solid;
}

.thumb-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(170px, 1fr));
  grid-gap: 10px;
  margin-top: 30px;
}

#draggable-boundary {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;

  pointer-events: none;
}

#draggable-participant {
  /* width: 80px;
  height: 60px; */

  background-color: #333;
  border-radius: 10px;
  width: 195px;
  height: 160px;
  overflow: hidden;
  opacity: 0;

  -webkit-animation: fadein 1s ease-in normal forwards;
  -moz-animation: fadein 1s ease-in normal forwards;
  animation: fadein 1s ease-in normal forwards;

  pointer-events: all;

  box-shadow: -1px 0px 35px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 0px 35px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 0px 35px -8px rgba(0, 0, 0, 0.75);
}

#draggable-participant .participant {
  width: 100% !important;
  height: 100% !important;
}

@media screen and (max-width: 767px) {
  #draggable-participant {
    width: 150px;
    height: 120px;
  }

  .room-modal-backdrop .room-modal {
    width: 350px;
  }

  .modal-video-track-container {
    min-width: 270px;
    width: 270px;
    max-width: 270px;
    max-height: 180px;
    height: 180px;
    min-height: 180px;
  }

  .thumb-list {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
}

/* Animations */
@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Network */
.network-outer {
  width: 2em;
  height: 2em;
  padding: 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  right: 0;
}

.network-inner {
  display: flex;
  align-items: flex-end;
}

.network-inner div {
  width: 2px;
  margin-right: 1px;
}

.network-inner div:not(:last-child) {
  border-right: none;
}

.host-indicator {
  margin-right: 2px;
}

.floating-boundary {
  display: none;
}

.main-participant:hover .floating-boundary {
  display: inline;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.main-participant:hover .floating-boundary button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

/* image undraggable */

.participant img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
